import React from "react";
import Layout from "../components/layout";
import Title from "../components/title";
import { graphql } from "gatsby";
import { RichText } from 'prismic-reactjs';
import { format } from 'date-fns';

export default ({ location, data }) => (

	<Layout 
    path={location.pathname}>

    <Title
      subtitle="Changelog"
      title="We've been busy."
      width="max-w-sm sm:max-w-md md:max-w-lg lg:max-w-2xl" />

    <div className="container max-w-3xl mx-auto text-base space-y-16">

      { data.prismic.allChangelogs.edges.map(item => (
        <>
        <div className="richtext space-y-4 flex-1">

          <span className="date text-dark">
            { format( Date.parse(item.node.date.toString()), 'dd MMM yyyy') }
          </span>

          <hr />

          {item.node.image && (
            <figure>
              <img src={item.node.image.url} alt={item.node.image.alt} />
            </figure>
          )}

          {RichText.render(item.node.text)}

        </div>
        </>
      ))}

    </div>

	</Layout>

)

export const query = graphql`
{
  prismic {
    allChangelogs(sortBy: date_DESC) {
      edges {
        node {
          date
          image
          text
        }
      }
    }
  }
}`